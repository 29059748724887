<template>
<el-form ref="form" label-width="80px">
    <el-form-item :label="msg('关键字')">
        <el-input v-model="key" :placeholder="msg('添加后,只能进行修改')"></el-input>
    </el-form-item>
    <el-form-item v-for="item in resources" :key="item.langId" :label="item.langName">
        <el-input v-model="item.msg" :placeholder="msg('显示内容')" ></el-input>
    </el-form-item>
    <div style="text-align:center;">
        <el-button type="primary" size="mini"  @click="onSubmit"><i class="el-icon-check"></i><span>{{msg('确定')}}</span></el-button>
        <el-button  size="mini" v-on:click="$emit('close-dg')">{{msg('取消')}}</el-button>
    </div>
</el-form> 
</template>

<script>
export default {
  name: 'lanEdit',
  computed:{
      key: {
          get(){
              return this.resName? this.resName : this.keyName;
          },
          set(value){
              this.resName = value;
          }
      }, 
  },
  data(){
        return{
            resName:null
        }
    }, 
  props:{
      resources:Array,
      keyName:String
  },
  mounted: function(){
  },
  methods:{
    onSubmit(){
        var url = this.$kit.api.lang.resourceUpdata;
        var _this = this;
        var res = [];
        for(var i=0;i<_this.resources.length;i++){
            var id = _this.resources[i].id;
            res.push({id:id==null?null:id,langId:_this.resources[i].langId,name:_this.key,msg:_this.resources[i].msg})
        }

        this.$axios.post(url, res)
            .then(function(result){
                if(result.status){
                    _this.$message(result.msg || _this.msg('update-ok'));
                    _this.$emit("close-dg", true);
                }else{
                    _this.$message(result.msg || _this.msg('更新失败'));
                }
            });
    },
    clear(){
        this.key = null;
    }
  }
}
</script>

<style scoped lang="scss">


</style>
