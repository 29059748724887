<template>
<div class="charting" style="height:100%; position:relative;">
      <el-col :xs="24" :sm="8" class="left-box">
        <div class="row-wrap" :style="{height: isMinScreen?(docHeight+33)+'px':docHeight+'px'}" style="margin-left:2px;">
          <el-table :data="languages" style="width: 100%" size="mini" ref="dataTable"  stripe @row-click="selectRow">
            <el-table-column prop="id" label="" min-width="60"></el-table-column>
            <el-table-column prop="label" label="" min-width="90"></el-table-column>
            <el-table-column prop="name" label="" min-width="70"></el-table-column>
            <el-table-column min-width="90"
                align="right">
                <template slot="header">
                    <el-button type="primary" size="mini" @click="addlan()">
                        <i class="el-icon-circle-plus-outline" style="display:inline-block;margin-right:4px;"></i>
                        <span>{{msg('添加')}}</span>
                    </el-button>
                </template>
                <template slot-scope="scope">
                    <el-button type="primary" icon="el-icon-edit" size="mini" circle @click.stop="editLan(scope.row)"></el-button>
                    <!-- <el-button type="danger" icon="el-icon-delete" size="mini"  circle @click.stop="deleteLan(scope.row)"></el-button> -->
                </template>
                </el-table-column>
          </el-table>
        </div>
    </el-col>
    <!-- end 左边 -->

    <!-- 右边 -->
    <transition name="my-animate"  
            enter-active-class="animate__animated animate__fadeInRight animate__faster" 
            leave-active-class="animate__animated animate__fadeOutRight animate__faster">
    <el-col :xs="24" :sm="16" :class="['right-box', isMinScreen?'min-charting':'']" v-if="!isMinScreen || visiableCharting">
        <div class="row-wrap" style="display:flex; justify-content: space-between; margin-right:5px;" >
          <div class="chart-title-wrap">
            <el-link :underline="false" type="primary" @click="closeCharting()" v-if="isMinScreen && visiableCharting">
                <i class="el-icon-back font22" ></i>
            </el-link>
          </div>
          <el-button type="primary" size="mini" @click="addResource()">
            <i class="el-icon-circle-plus-outline" style="display:inline-block;margin-right:4px;"></i>
            <span>{{msg('添加')}}</span>
          </el-button>
        </div>
        <div class="row-wrap" :style="{height: isMinScreen? docHeight:(docHeight-49)+'px'}" style="margin-top:-3px;margin-right:5px;">
          <el-row :gutter="10"  class="query-wrap" style="margin-left: 0px;
              margin-right: 0px;">
            <el-col :xs="24" :sm="8" :lg="6" :xl="4">
                <el-input  :placeholder="msg('关键字')" v-model="query.name" size="small">
                  <i slot="prefix" class="el-input__icon el-icon-tickets"></i>
                </el-input>
            </el-col>
            <el-col :xs="24" :sm="8" :lg="6" :xl="4">
                <el-input  :placeholder="msg('内容')" v-model="query.msg" size="small">
                  <i slot="prefix" class="el-input__icon el-icon-chat-dot-square"></i>
                </el-input>
            </el-col>
            <el-col :span="2" style="text-align:right;">
              <el-button-group>
                <el-button type="success" @click="getResources()" size="mini">
                  <i class="el-icon-search" style="display:inline-block;margin-right:4px; "></i>
                  <span>{{msg('搜索')}}</span>
                </el-button>
              </el-button-group>
            </el-col>
          </el-row>
          <vxe-grid ref="dataTable" :height="docHeight-310" 
              resizable border
              show-overflow highlight-hover-row
              highlight-current-row highlight-hover-column highlight-current-column
              :data="resources"
              :columns="columns"
              @cell-click="checkRow"
          ></vxe-grid>

          <pagenator
              @page-change="handlerPageChange"
              :query="query"
          >
          </pagenator>
        </div>
    </el-col>
    </transition>

  <el-dialog :title="dglogTitle" :visible.sync="dialogTableVisible" :fullscreen="isMinScreen" :close-on-click-modal="false">
    <lan-edit :language="currLan" v-on:close-dg="closeEditDialog" ref="lanEdit"/>
  </el-dialog>
  <el-dialog :title="dglogTitle" :visible.sync="dialogTableVisible1" :fullscreen="isMinScreen" :close-on-click-modal="false">
    <res-edit :resources="currRes" :keyName="keyName" v-on:close-dg="closeEditDialog1" ref="resEdit"/>
  </el-dialog>
</div>
</template> 

<script>
import 'animate.css';
import LanEdit from "./I18nEdit.vue";
import ResEdit from "./I18nResourceEdit.vue";
import Pagenator from "../../components/Pagination.vue";

export default {
    name:'language',
    components: {
        LanEdit:LanEdit,
        ResEdit:ResEdit,
        Pagenator:Pagenator,
    },
    mounted:function(){
      this.getLanguages();
      this.visiableCharting = this.mi
    },
    data(){
        return{
            query:{  //查询条件
                length: 10, //每页显示的长度
                start: 0, //起始数量
                pageIndex: 1,
                pageTotal:0,//总记录数
                name:null,
                msg:null,
            },

            showQuery: false,

            languages: [],   //语言列表
            resources: [],   //语言设置列表
            visiableCharting: true, //是否打开聊天窗口
            curr:null,
            dgGrangMenuVisiable: false,
            dialogTableVisible:false,
            dialogTableVisible1:false,
            dglogTitle: "",
            currLan: {id: null, label: null, name: null, defaulted: null},   //当前列表中选中的id,
            currRes:[],
            keyName:"",

            columns: [
                {field: "id", title: this.msg("ID"), minWidth: 60,sortable: true},
                {field: "langName", title: this.msg("语言名"), minWidth: 120,sortable: true},
                {field: "name", title: this.msg("关键字"), minWidth: 200,sortable: true},
                {field: "msg", title: this.msg("内容"), minWidth: 200,sortable: true},
            ],
        }
    }, 
    computed: {
        isMinScreen: {  //是否小屏幕，浏览器
            get() {
              var is = this.$store.state.isMinScreen;
              if(!is){
                  this.openCharting();
              }
              return is;
            }
        },
        docHeight: {  //表格的高度
            get() {
            var height =  this.$store.state.docHeight;
            return height - 120;
            }
        },
        routeTab: {
            get(){
                try{
                    return this.$store.state.activeMenus[this.$route.path]
                }catch{
                    return null;
                }
            }
        }
    },
    methods:{
        refresh: function(refresh){  //刷新列表
            this.$kit.clearQuery(this.query);
            this.getResources(refresh);
        },
        openCharting(){ //打开右侧窗口
            this.visiableCharting = true;
        },
        closeCharting(){ //关闭右侧窗口
            this.visiableCharting = false; 
        },
        getLanguages:function(){//获取语言列表
            var _this = this;
            var param = this.$kit.rebuildQuery(this.query);
            
            this.axios.post(this.$kit.api.lang.languageList, param).then(function (result) {
                if(result.status){
                    if(_this.languages.length>0)
                        _this.languages.splice(0, _this.languages.length);
                    
                    var roleDatas = result.data;

                    for(var i=0; i<roleDatas.length; i++){
                        var item = roleDatas[i];
                        if(item.created){
                            item.created = item.created.substring(0,10);
                        }

                        _this.languages.push(item);
                    } 

                }else{
                    _this.$message(result.msg);
                }
                
            }).catch(function (error) {
                console.error(error);
            });
        },
        getResources:function(){
            var _this = this;
            var param = this.$kit.rebuildQuery(this.query);
            if(this.curr && this.curr.id){
              param["langId"] = this.curr.id;
            }
            this.axios.post(this.$kit.api.lang.resourceList, param)
              .then(function (result) {
                if(result.status){
                     _this.resources = result.data.list;
                     _this.query.pageTotal = result.data.total;

                }else{
                    _this.$message(result.msg);
                }
                
            }).catch(function (error) {
                console.error(error);
            });
        },
        //分页数据发生改变，回调事件：info[0]: 每页显示的数量，info[1]: 起始页
        handlerPageChange(info){
            this.query.length = info[0];
            this.query.start = info[1];
            this.getResources();
        },
        //点击行，选中行
        checkRow({row, $event}){
            var _this = this;
           _this.currRes = [];
            this.$axios.get(this.$kit.api.lang.getRes+"?id="+row.id)
                .then(function(result){
                    if(result.status && result.data.length>0){
                      var data = result.data;
                        _this.keyName = data[0].name;
                        if(data.length == _this.languages.length){
                          for(let i=0; i<data.length; i++){
                              _this.currRes.push(data[i]);
                          }
                        }else{

                          for(let i=0; i<_this.languages.length; i++){
                            var lan = _this.languages[i];
                            var item = data.find(d => d.langId == lan.id);
                            if(item){
                              _this.currRes.push(item);
                            }else{
                              _this.currRes.push({id: null, langId: lan.id, langName: lan.label, name: _this.keyName, msg: _this.keyName});
                            }
                          }
                        }
                    }
              });
            this.dglogTitle = this.msg('编辑语言内容');
            this.dialogTableVisible1 = true;
        },
        reset(){
            this.$kit.clearQuery(this.query);
        },
        closeEditDialog(type){  //编辑弹窗关闭后的回调事件
            if(type){
                this.getLanguages();
            }
            this.dialogTableVisible = false;
        },
        closeEditDialog1(type){  //编辑弹窗关闭后的回调事件
            if(type){
                this.getLanguages();
                this.getResources();
            }
            this.$refs.resEdit.clear();
            this.dialogTableVisible1 = false;
        },
        selectRow(language, column, event){
          this.curr = language;
          this.getResources();
          this.openCharting()
        },
        addlan:function(){//添加语言
            this.currLan = {id: null, label: null, name: null, defaulted: null};
            this.dglogTitle = this.msg('添加语言');
            this.dialogTableVisible = true;
        },
        editLan(row, type){
            this.currLan = row;
            this.dglogTitle = this.msg('添加语言');
            this.dialogTableVisible = true;
        },
        addResource:function(){
          var _this = this;
          _this.currRes = [];
            this.$axios.get(this.$kit.api.lang.getRes+"?id=")
                .then(function(result){
                    if(result.status){
                        var data = result.data;
                        for(var i=0; i<data.length; i++){
                            _this.currRes.push(data[i]);
                        }
                    }
              });
            this.dglogTitle = this.msg('添加语言内容');
            this.keyName = null;
            this.dialogTableVisible1 = true;
        },
        deleteLan(row){
            var _this = this;
            this.$confirm(this.fmt('确定要删除《%s》吗?', [row.label]), this.msg('提示'), {
                confirmButtonText: this.msg('确定'),
                cancelButtonText: this.msg('取消'),
                type: 'warning'
            }).then(() => {
                _this.$axios.post(_this.$kit.api.lang.languageDelete, [row.id])
                .then(function(result){
                    _this.$message(result.msg || _this.msg('删除成功'));
                    _this.refresh(true);
                });
            }).catch((err) => {
                // console.log(err);
            });

        },
        handleSizeChange(val) {  //分页的每页数据变化后的回调事件
          //console.log(`每页 ${val} 条`);
          this.query.length = val;
          this.query.start = 0;
          this.getResources();
        },
        handleCurrentChange(val) {   //分页的当前页改变后的回调事件
          //console.log(`当前页: ${val}`);
          this.query.start = this.query.length * (val -1);
          this.getResources();
        },
    }
}
</script>
<style scoped lang="scss">
.min-charting{
    position: absolute; top:0px; left:0px; z-index: 10;
}
.chart-title-wrap{display:flex;  align-items: center;box-sizing: content-box;
}
.charting{
    margin-left: 1px;
    margin-right: -7px;
    padding-right: 5px;
}
</style>